/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const RememberContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 10px 0 20px 0;
  .formCheckbox {
    display: none;
  }

  .formLabel {
    font-size: 15px;
    color: ${props => (props.$isMadStore ? '#414141 !important' : '#4b5661')};
    line-height: 20px !important;
    position: relative;
    cursor: pointer;
  }

  .formCheckbox + .formLabel::before {
    content: '';
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    border: 1px solid ${props => (props.$isMadStore ? '#c5ced4' : '#ababab')};
    margin-right: 10px;
    border-radius: 4px;
  }

  .formCheckbox:checked + .formLabel::after {
    content: '';
    background-size: cover;
    display: block;
    position: absolute;
    ${props =>
      props.$isMadStore
        ? `background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='%23227979' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.773438' y='0.5' width='19' height='19' rx='1.5' stroke='%23227979'/%3E%3Cpath d='M5.07812 9.33083L8.53966 12.7924L15.0781 6.25391' stroke='%23F5F5F3' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");width: 20px;height: 20px;border-radius: 4px;top: 0px;left: 0px;`
        : `width: 9px;height: 9px;background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E90D8E7A6-8D71-473D-A5B6-0B72D1DC81CC%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Login' transform='translate(-258.000000, -487.000000)' fill='%2300A2DA' fill-rule='nonzero'%3E%3Cg id='Group-4' transform='translate(253.000000, 482.000000)'%3E%3Cpath d='M13.945361,5.22510388 L8.02703126,11.2794894 L6.42691613,9.4617755 C5.7281759,8.86053167 4.62416634,9.56664361 5.12725931,10.4755006 L7.02783273,13.6984471 C7.32829103,14.1039371 8.02703126,14.5094272 8.73275889,13.6984471 C9.03321719,13.2999483 14.7419249,6.13396083 14.7419249,6.13396083 C15.4476525,5.32997199 14.5462776,4.62386005 13.945361,5.22510388 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");top: 6px;left: 6px;`}
  }
`;
