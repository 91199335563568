import { isEmpty } from 'lodash';
// import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { bcApi } from '../../../helpers/bigcommerce';
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
// import { validatePhoneNumber } from '../../../helpers/general';
import { useScript } from '../../../helpers/general';
// import { predictAddress, getAddressDetails } from '../../../helpers/geoscape';
import { Select } from '@chakra-ui/react';
import * as styles from './AddressForm.module.css';
import { RememberContainer } from './AddressForm.styled';
const defaultAddress = {
  address1: '',
  city: '',
  company: '',
  country_code: 'AU',
  first_name: '',
  last_name: '',
  phone: '',
  postal_code: '',
  state_or_province: ''
};

const required = {
  first_name: 'First Name',
  city: 'City',
  country_code: 'County',
  state_or_province: 'State/Province',
  last_name: 'Last Name',
  address1: 'Address 1',
  postal_code: 'Postcode/Zip',
  phone: 'Phone'
};

let autoComplete;
const AddressForm = ({
  address = {},
  onCancel,
  onSubmit,
  visible,
  isSaving,
  updateAutoShip,
  setUpdateAutoShip,
  isLoading
}) => {
  const [addressData, setAddressData] = useState(defaultAddress);
  const [countries, setCountries] = useState([]);
  const [countryStates, setCountryStates] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [formErr, setFormErr] = useState({});

  /* Used with Geoscape address */
  // const [addressResults, setAddressResults] = useState(false);
  // const [addressMessage, setAddressMessage] = useState(false);

  /* Google maps autocomplete */
  const [acInit, setAcInit] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState({});
  const autoCompleteRef = useRef(null);

  const fetchStates = countryIso => {
    setIsFetching(true);
    let countryObject = false;
    countries.map(country => {
      if (country.country_iso2 === countryIso) {
        countryObject = country;
      }
      return true;
    });

    const endpoint = countryObject.states?.resource?.substring(1);
    bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(({ response, status }) => {
      if (status === 200) {
        setCountryStates(response);
      } else {
        setCountryStates([]);
      }
      setIsFetching(false);
    });
  };

  const handleUpdateValue = e => {
    const { value, name } = e.target;
    if (name === 'country_code') {
      setAddressData({ ...addressData, [name]: value, state_or_province: '' });
      fetchStates(value);
    } else {
      setAddressData({ ...addressData, [name]: value });
    }
    window.addressData = addressData;
    setFormErr({});
  };

  const handleSubmit = () => {
    const errObj = {};
    let valid = true;
    Object.keys(required).forEach(key => {
      if (!addressData[key]) {
        valid = false;
        errObj[key] = `${required[key]} can not be blank.`;
      }
    });

    if (!valid) {
      setFormErr(errObj);
    } else {
      onSubmit(addressData);
    }
  };

  useEffect(() => {
    if (!isEmpty(address)) {
      setAddressData(address);
      if (address.country_code) {
        fetchStates(address.country_code);
      }
    } else {
      fetchStates('AU');
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    if (!visible) {
      setAddressData(defaultAddress);
      setCountryStates([]);
      setFormErr({});
    }
  }, [visible]);

  useEffect(() => {
    setAddressData(data => ({ ...data, ...autocompleteData }));
  }, [autocompleteData]);

  useEffect(() => {
    bcApi(`countries?limit=250`, 'GET', null, 2).then(response => {
      if (response.status === 200) {
        setCountries(response.response);
      }
    });
  }, []);

  /* Google maps autocomplete */
  useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API}&libraries=places`,
    'google',
    () => {
      if (typeof document !== 'undefined') {
        const autocompleteField = document.getElementById('autocompleteField');
        if (autocompleteField && !acInit) {
          setAcInit(true);
          initialiseAutoComplete('', setAutocompleteData, autoCompleteRef);
        }
      }
    }
  );

  const initialiseAutoComplete = (updateQuery, updateAutocompleteData, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions: { country: 'au' }
    });
    autoComplete.setFields(['address_components', 'formatted_address']); // specify what properties we will get from API
    // add a listener to handle when the place is selected
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery, updateAutocompleteData)
    );
  };

  const handlePlaceSelect = async (updateQuery, updateAutocompleteData) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    const addressComponents = {};
    addressObject.address_components.map(component => {
      const types = component.types.filter(type => type !== 'political');
      addressComponents[types[0]] = component;
      return true;
    });

    updateAutocompleteData({
      address1: `${addressComponents.street_number?.long_name || ''} ${
        addressComponents.route?.long_name
      } `,
      city: addressComponents.locality?.long_name,
      country_code: addressComponents.country?.short_name,
      postal_code: addressComponents.postal_code?.long_name,
      state_or_province: addressComponents.administrative_area_level_1?.long_name
    });
  };

  /* Geoscape autocomplete */
  // const triggerSearch = debounce(async (address) => {
  //   const suggestions = await predictAddress(address);
  //   if (suggestions) {
  //     if (suggestions.type === 'results') {
  //       setAddressResults(suggestions.results);
  //     } else {
  //       setAddressResults(false);
  //       setAddressMessage(suggestions[suggestions.type]);
  //     }
  //   }
  // }, 200, {trailing: true});

  // const searchAddress = (value) => {
  //   setQuery(value);
  //   triggerSearch(value);
  // }

  // const selectAddress = async (addressId) => {
  //     const addressData = await getAddressDetails(addressId);
  //     if (addressData.type === 'address') {
  //         const field = {};
  //         const addressProps = addressData.address.properties;
  //         // console.log(addressProps);
  //         const addressField = `${addressProps.street_number_1} ${addressProps.street_name} ${addressProps.street_type}`;
  //         // console.log("Full Address: ", addressField);
  //         field.address1 = addressField;
  //         field.city = addressProps.locality_name;
  //         field.state_or_province = stateTranslations[addressProps.state_territory];
  //         field.country_code = 'AU';
  //         field.postal_code = addressProps.postcode;

  //         setAddressResults(false);
  //         setAutocompleteData(field);
  //     } else {
  //         // TODO: Throw error
  //     }
  // }

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className="grid grid-50">
        <div className="formField required">
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            name="first_name"
            required
            onChange={handleUpdateValue}
            value={addressData.first_name}
          />
          {formErr.first_name && <span className="error">{formErr.first_name}</span>}
        </div>
        <div className="formField required">
          <label htmlFor="last_name">Last Name</label>
          <input
            type="text"
            name="last_name"
            required
            onChange={handleUpdateValue}
            value={addressData.last_name}
          />
          {formErr.last_name && <span className="error">{formErr.last_name}</span>}
        </div>
      </div>
      <div className="formField required">
        <label htmlFor="phone">Phone</label>
        <input
          type="text"
          name="phone"
          required
          onChange={handleUpdateValue}
          value={addressData.phone}
          maxLength={13}
        />
        {formErr.phone && <span className="error">{formErr.phone}</span>}
      </div>

      <div className="formField">
        <label htmlFor="company">Company</label>
        <input
          type="text"
          name="company"
          onChange={handleUpdateValue}
          value={addressData.company}
        />
      </div>

      <div className="formField required">
        <label htmlFor="country_code">Country</label>
        {!isEmpty(countries) && (
          <Select
            required
            name="country_code"
            onChange={handleUpdateValue}
            onBlur={handleUpdateValue}
            value={addressData.country_code}
          >
            <option>Select a country</option>
            {countries.map((country, countryIndex) => (
              <option key={countryIndex} value={country.country_iso2}>
                {country.country}
              </option>
            ))}
          </Select>
        )}
        {formErr.country_code && <span className="error">{formErr.country_code}</span>}
      </div>

      {addressData.address1 === '' && (
        <>
          <div className="formField">
            {/* <span className="label">Enter your address</span> */}
            {/* Google Maps autocomplete */}
            {/* <input type="text" autoComplete="new-password" id="autocompleteField" ref={autoCompleteRef} onChange={event => setQuery(event.target.value)} value={query} /> */}
            {/* Geoscape autocomplete */}
            {/* <input type="text" autoComplete="new-password" id="address_search" onChange={(e) => searchAddress(e.target.value)} value={query} />
            {addressResults && (
                <div className={styles.addressSearchResults}>
                    {addressResults.map((address, addressIndex) => {
                        return (
                            <div key={addressIndex} role="presentation" onClick={() => selectAddress(address.id)}>
                                {address.address}
                            </div>
                        )
                    })}
                </div>
            )}
            {addressMessage && (
                <div className={styles.addressMessage}></div>
            )} */}
          </div>
          {/* <div className="formField">
            <span className="label">Or enter below:</span>
          </div> */}
        </>
      )}
      <div className="formField required">
        <label htmlFor="address1">Address</label>
        <input
          type="text"
          name="address1"
          required
          onChange={handleUpdateValue}
          value={addressData.address1}
        />
        {formErr.address1 && <span className="error">{formErr.address1}</span>}
      </div>

      <div className="formField required">
        <label htmlFor="city">Suburb</label>
        <input
          type="text"
          name="city"
          required
          onChange={handleUpdateValue}
          value={addressData.city}
        />
        {formErr.city && <span className="error">{formErr.city}</span>}
      </div>

      <div className="grid grid-50">
        <div className="formField required relative">
          <label htmlFor="state_or_province">State</label>
          {!isEmpty(countryStates) && (
            <Select
              disabled={isFetching}
              name="state_or_province"
              required
              value={addressData.state_or_province}
              onChange={handleUpdateValue}
              onBlur={handleUpdateValue}
            >
              <option>Select a state</option>
              {countryStates.map((state, stateIndex) => (
                <option key={stateIndex} value={state.state}>
                  {state.state}
                </option>
              ))}
            </Select>
          )}
          {isEmpty(countryStates) && (
            <input
              type="text"
              name="state_or_province"
              required
              value={addressData.state_or_province}
              onChange={handleUpdateValue}
            />
          )}

          {formErr.state_or_province && <span className="error">{formErr.state_or_province}</span>}
        </div>

        <div className="formField required">
          <label htmlFor="postal_code">Postcode</label>
          <input
            type="text"
            name="postal_code"
            required
            onChange={handleUpdateValue}
            value={addressData.postal_code}
          />
          {formErr.postal_code && <span className="error">{formErr.postal_code}</span>}
        </div>
      </div>
      <RememberContainer>
        <input
          type="checkbox"
          className="formCheckbox"
          id="remember_me"
          name="updateAutoShip"
          checked={updateAutoShip}
          onChange={e => setUpdateAutoShip(e.target.checked)}
          value={updateAutoShip}
        />
        <label className="formLabel" htmlFor="remember_me">
          Update Autoship address
        </label>
      </RememberContainer>
      <div className="row mt-3">
        <Button
          type="span"
          level="tertiary"
          className={isSaving ? 'disabled' : ''}
          onClick={handleSubmit}
        >
          {!isSaving ? 'Save address' : 'Saving...'}
        </Button>
        <Button
          type="span"
          level="ghost"
          onClick={() => {
            setAcInit(false);
            onCancel();
          }}
          className={styles.ml4}
        >
          Cancel
        </Button>
      </div>
      {(isSaving || isLoading) && (
        <div className={styles.rootSpinner}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default AddressForm;
