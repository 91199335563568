import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { bcApi } from '../../helpers/bigcommerce';

import AddressCard from '../../components/molecules/AddressCard/AddressCard';
import AddressForm from '../../components/molecules/AddressForm/AddressForm';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';

import { useQuery } from '@tanstack/react-query';
import * as styles from './addresses.module.css';
import { getStorage } from 'helpers/general';

const Addresses = ({ customerId }) => {
  const [active, setActive] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: addresses, isLoading, refetch } = useQuery(
    ['addresses', customerId],
    async () => await bcApi(`customers/addresses?customer_id:in=${customerId}`)
  );
  const [updateAutoShip, setUpdateAutoShip] = useState(false);

  const addAddress = () => {
    setActive({});
    setUpdateAutoShip(false);
  };

  const editAddress = address => {
    setActive(address);
    setUpdateAutoShip(false);
  };

  const cancelUpdate = () => setActive(null);

  const saveAddress = async address => {
    if (isEmpty(address)) {
      return;
    }
    if (loading) return;
    try {
      setLoading(true);
      const token = getStorage('_loggedIn');
      if (!token) {
        if (!response.ok) {
          throw new Error('Failed to fetch prescriptions');
        }
      }
      const api_url = process.env.GATSBY_BC_API;
      const response = await fetch(`${api_url}/v1/customer/address`, {
        method: 'PUT',
        body: JSON.stringify({ ...address, updateAutoShip }),
        headers: {
          'Content-Type': 'application/json',
          'authorization-BC': token
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch prescriptions');
      }
      const textResponse = await response.text();
      const data = await JSON.parse(textResponse);
      if (!data.success) {
        setError('Update failed. Please try again.');
        return;
      }
      setError(null);
      setActive(null);
      refetch();
    } catch (error) {
      console.log('error', error);
      setError(
        'Please try again. If the issue persists, feel free to contact our support team for assistance!'
      );
    } finally {
      setLoading(false);
    }
  };

  const removeAddress = addressId => {
    // delete address
    const endpoint = `customers/addresses?id:in=${addressId}`;
    bcApi(endpoint, 'DELETE').then(response => {
      if (response.status === 204) {
        refetch();
      }
    });
  };

  return (
    <>
      <h1 className={`center-title ${styles.pageTitle}`}>Addresses</h1>
      {!isLoading && addresses && addresses.response.data.length === 0 && (
        <div className={styles.pageEmpty}>You have no saved addresses</div>
      )}
      {error && <p className={styles.error}>{error}</p>}
      {!isLoading && !active && (
        <div className={styles.container}>
          {addresses?.response?.data?.map((address, addressIndex) => (
            <AddressCard
              address={address}
              key={addressIndex}
              onEdit={() => editAddress(address)}
              onDelete={() => removeAddress(address.id)}
            />
          ))}

          <AddressCard onAddAddress={addAddress} />
        </div>
      )}

      <AddressForm
        address={active}
        visible={!!active}
        isSaving={isLoading}
        onSubmit={saveAddress}
        onCancel={cancelUpdate}
        updateAutoShip={updateAutoShip}
        setUpdateAutoShip={setUpdateAutoShip}
        isLoading={loading}
      />

      {isLoading && <span>Fetching your stored addresses...</span>}
    </>
  );
};

const AddressesOutput = () => (
  <AccountPageWrapper metaTitle="Pet Chemist Online - Your Address Book" title="Addresses">
    <Addresses />
  </AccountPageWrapper>
);

export default AddressesOutput;
